@import "../_prestashop/components/featuredproducts.scss";

.ib-btn-home {
  
  padding: 0 45px 0 5px;
  // line-height: get-vw(50);
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  i {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 40px;
    font-size: 20px;
  }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-up(lg) {
  .ib-btn-home {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 get-vw(45) 0 get-vw(5);
    font-size: get-vw(14);
    i {
      width: get-vw(40);
      font-size: get-vw(20);
    }
  }
}