// INDEX ENDPOINT
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "partials/_variables.scss";
@import "partials/_mixins.scss";
@import "partials/_functions.scss";

@import "components/featuredproducts.scss";
@import "modules/ibifboxes.scss";
@import "modules/ibifseotext.scss";


// h2 {
#index h2 {
    // font-size: 1.875rem;
    font-size: 25px;
    margin-bottom: 1.5rem;
}
#index #layerslider_3_2{
    display: none;
}

/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(xs) {
    .products.swiper .product.swiper-slide {
        // max-width: 290px;
        max-width: 230px;
    }
}
@include media-breakpoint-up(lg) {
    #index h2 {
        font-size: get-vw(1.875rem);
        margin-bottom: get-vw(1.5rem);
    }
}
