/*** IBIF ***/

section.ibif-seotext {
    border: 1px solid $block-border-color;
   
    h2::before {
        content: '';
        width: 15px;
        // margin-bottom: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid;
        display: block;
    }
    .ib-item {
        p {
            text-align: justify;
            line-height: 1.5;
        }
    }
    .ib-showall {
        background: none;
        top: 8px;
    }
}
.ibif-seotext{
    h4{
        color:$primary-color;
        font-weight: 600;
    }
}

/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(sm) {
    .ib-hidden {
        display: none;
    }
}
@include media-breakpoint-up(lg) {
    section.ibif-seotext {
        border: none;
        h2::before {
            width: get-vw(15);
            margin-top: 0;
            margin-bottom: get-vw(40);
        }
        // .ib-item {
        //     p {
        //         line-height: 1.5;
        //     }
        // }
    }
}
