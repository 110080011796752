@import "../_prestashop/partials/_variables.scss";

// themes/ibif/**/*.{scss,tpl}

// $rem-value: 16px;    // moved to _functions
$spacer: 1rem !default;
$input-bg-color: $white;
$input-border-color: #eaeaea;
$input-text-color: #9d9d9d;
$brand-info: #5bc0de !default;
$black: #000000;
$brown: #b39573;
$green: #63C900;
$red: #f00;
$whiteSmoke: #F5F5F5;
$primary-color:#F7473A;
$ib-index-hr-gray: #eeeeee;
$block-border-color: #E7E7E7;
$block-icon-color: #050505;
$pagination-blog-border-color: #D4D0CC;
$breadcrumb-color: #9C9C9C;
$brand-primary: $brown;
$circle-green: #31bc1b;
$circle-red: #E73A3A;
$circle-orange: #E78E3A;
$radio-border-color: $black;

// transition speeds
$tspeed-200: .2s;
$tspeed-250: .25s;
$tspeed-300: .3s;
$tspeed-500: .5s;
$tspeed-800: .8s;