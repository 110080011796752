/*** IBIF ***/

.ibif-boxes {
    .ib-item {
        > a {
            padding-bottom: 44.5%;
            background: url('../img/gradient_01.png');
            background-size: 100% 100%;
        }
        > span {
            background-size: cover;
        }
    }
    .ib-bottom {
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100%;
        transition: max-width $tspeed-500 ease;
    }
    .ib-item:hover .ib-bottom {
        max-width: 95%;
    }
    .ibe_block-grow {
        --scale: 1.1;
        --speed: #{$tspeed-500};
        --ease: ease;
    }
    .icon {
        text-align: center;
        border: 2px solid;
        // width: 34px;
        // height: 34px;
        // line-height: 30px;
        // font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 19px;
        font-size: 12px;
    }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
    .ibif-boxes {
        .h4 {
            font-size: 18px;
        }
    }
}
@include media-breakpoint-up(lg) {
    .ibif-boxes {
        .icon {
            border-width: get-vw(2);
            width: get-vw(34);
            height: get-vw(34);
            line-height: get-vw(30);
            font-size: get-vw(20);
        }
    }
}