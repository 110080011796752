$rem-value: 16px;

// scale values with viewport width
@function get-vw($target, $resolution: 1920) {
    $value: int($target);
    @if unit($target) == 'rem' {
        // $value: $value * 16;
        $value: $value * $rem-value;
    }
    $value: $value + 0px;   // cast $target as pixels
    $vw-context: ($resolution * .01px);
    @return ($value / $vw-context) * 1vw;
}
  
@function int($value) {
    @return $value / ($value * 0 + 1);
}